/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function doTheCentering(centeree, centerer, absolute) {
    centeree = $(centeree);

    var centereeHeight = centeree.outerHeight();
    var centererHeight = $(centerer).height();

    var position = 'relative';

    if(absolute) {
        position = 'absolute';
    }

    // Do it
    centeree.css({
        'top': (centererHeight / 2) - (centereeHeight / 2),
        'position': position
    });
}

function centerTheMass(centeree, centerer, absolute) {
    $(window).load(function() {
        doTheCentering(centeree, centerer, absolute);
    });

    $(window).on('resize', function() {
        doTheCentering(centeree, centerer, absolute);
    });
}

// Foundation
$(document).foundation(); // Foundation JavaScript

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.testimonial-slider').slick({
            arrows: false,
            dots: true,
            customPaging: function(slider, i) {
                return '<a href="#" class="slick-dot fa fa-circle-thin" type="button" data-role="none" role="button" aria-required="false" tabindex="0"></a>';
            }
        });
        $('.srp-widget-container').append('<a href="http://localhost:8888/pacific-homes/category/current-projects/" class="read-more">View All Projects <span class="fa fa-chevron-circle-right"></span></a>');
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        centerTheMass('.testimonials-inner', '.testimonials');
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
